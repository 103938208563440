<template>
  <div class="page">
    <!-- Header -->
    <div class="header">
      <button @click="openNotificationForm" class="common-btn">
        Send Mass Notification
      </button>
    </div>

    <!-- Notification Form Modal -->
    <div
      v-if="showNotificationForm"
      class="form-overlay"
      @click="closeNotificationForm"
    >
      <div class="form-modal" @click.stop>
        <h3>Send Mass Notification</h3>
        <form @submit.prevent="handleSubmit">
          <!-- Title -->
          <div class="form-group">
            <label for="title">Title:</label>
            <input
              type="text"
              id="title"
              v-model="formData.title"
              placeholder="Enter notification title"
              required
            />
          </div>

          <!-- Dynamic Field Based on Notification Type -->
          <div class="form-group">
            <label for="body">Notification Body:</label>
            <textarea
              id="body"
              v-model="formData.body"
              placeholder="Enter notification body"
              required
            ></textarea>
          </div>

          <!-- Notification Type -->
          <div class="form-group">
            <label for="type">Notification Type:</label>
            <select
              id="type"
              v-model="formData.type"
              @change="handleTypeChange"
              required
            >
              <option value="text">Text</option>
              <option value="url">URL</option>
              <option value="image">Image</option>
            </select>
          </div>

          <!-- Dynamic Field Based on Notification Type -->
          <div class="form-group" v-if="formData.type === 'text'">
            <label for="body">Full Message:</label>
            <textarea
              id="body"
              v-model="formData.text"
              placeholder="Enter the full message"
              required
            ></textarea>
          </div>

          <div class="form-group" v-if="formData.type === 'url'">
            <label for="url">YouTube URL:</label>
            <input
              type="url"
              id="url"
              v-model="formData.body"
              placeholder="Enter URL"
              required
            />
          </div>

          <div class="form-group" v-if="formData.type === 'image'">
            <label for="image">Upload Image:</label>
            <input
              type="file"
              id="image"
              accept="image/*"
              @change="handleImageUpload"
              required
            />
          </div>

          <!-- Submit and Cancel Buttons -->
          <div class="form-actions">
            <button
              type="button"
              class="cancel-btn"
              @click="closeNotificationForm"
            >
              Cancel
            </button>
            <button type="submit" class="common-btn">Send Notification</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Notifications List -->
    <div v-if="loading" class="loading">Loading...</div>
    <div v-else-if="error" class="error">{{ error }}</div>
    <div v-else class="notification-table">
      <table>
        <thead>
          <tr>
            <th>Title</th>
            <th>Short Message</th>
            <th>Message Type</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="notification in notifications" :key="notification.id">
            <td>{{ notification.messageTitle }}</td>
            <td>{{ notification.shortMessage }}</td>
            <td>{{ notification.messageType }}</td>
            <td>
              <span
                :class="{
                  success: notification.delivered,
                  failed: !notification.delivered,
                }"
              >
                {{ notification.delivered ? "Delivered" : "Failed" }}
              </span>
            </td>
            <td>
              <button
                class="delete-btn"
                @click="deleteNotification(notification.id)"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MassNotifications",
  props: {
    churchId: {
      type: Number,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    urlName: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      notifications: [],
      loading: true,
      error: null,
      showNotificationForm: false,
      formData: {
        title: "",
        body: "",
        type: "text", // Default type
        text: "",
        url: "https://",
      },
      imageFile: null, // Store uploaded image file
    };
  },
  methods: {
    async fetchNotifications() {
      try {
        this.loading = true;
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/churches/inbox`,
          { churchId: this.churchId },
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.notifications = Array.isArray(response.data) ? response.data : [];
      } catch (err) {
        this.error = "Failed to fetch notifications.";
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    openNotificationForm() {
      this.showNotificationForm = true;
    },
    closeNotificationForm() {
      this.showNotificationForm = false;
      this.resetFormData();
    },
    resetFormData() {
      this.formData = {
        title: "",
        body: "",
        type: "text",
      };
      this.imageFile = null;
    },
    handleTypeChange() {
      // Clear the body/input field when type changes
      this.formData.body = "";
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.imageFile = file;
      }
    },
    async handleSubmit() {
      try {
        const formData = new FormData();
        formData.append("urlName", this.urlName);
        formData.append("churchId", this.churchId);
        formData.append("messageTitle", this.formData.title);
        formData.append("shortMessage", this.formData.body);
        formData.append("fullMessage", this.formData.text);
        formData.append("messageType", this.formData.type);
        formData.append("viedoUrl", "");
        formData.append("createdBy", this.userId);

        // Append additional fields dynamically based on the type
        if (this.formData.type === "image") {
          if (this.imageFile) {
            formData.append("file", this.imageFile);
          } else {
            alert("Please upload an image.");
            return;
          }
        } else {
          formData.append("body", this.formData.body || ""); // Default empty string if no body
        }

        const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.post(
          `${apiBaseUrl}/churches/admin-inbox-send`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        alert("Notification sent successfully!");
        console.log("Response:", response.data);

        // Close the modal and refresh the notifications list
        this.closeNotificationForm();
        this.fetchNotifications();
      } catch (err) {
        alert("Failed to send notification.");
        console.error("Error:", err);

        // Handle specific errors (optional)
        if (err.response && err.response.data.errors) {
          const errorMessages = Object.values(err.response.data.errors)
            .flat()
            .join("\n");
          alert(`Validation Errors:\n${errorMessages}`);
        }
      }
    },
    async deleteNotification(notificationId) {
      if (!confirm("Are you sure you want to delete this notification?")) {
        return;
      }

      try {
        await axios.delete(
          `${process.env.VUE_APP_API_BASE_URL}/churches/admin-inbox/${notificationId}`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );

        alert("Notification deleted successfully!");
        // Refresh the notifications list
        this.fetchNotifications();
      } catch (err) {
        console.error("Error deleting notification:", err);
        alert("Failed to delete notification.");
      }
    },
  },
  mounted() {
    this.fetchNotifications();
  },
};
</script>

<style scoped>
.mass-notifications {
  padding: 24;
  max-width: 800px;
  margin: 0 auto;
}

/* Modal Styling */
.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.form-modal {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 600px;
  overflow-y: auto;
}

/* Form Styles */
.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

input,
textarea,
select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

textarea {
  resize: none;
  height: 100px;
}

/* Buttons */
.common-btn,
.cancel-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.common-btn {
  background-color: #07538f;
  color: white;
}

.common-btn:hover {
  background-color: #00426c;
}

.cancel-btn {
  background-color: #ff8500;
  color: white;
}

.cancel-btn:hover {
  background-color: #e07600;
}

.notification-table {
  margin-top: 1rem;
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  padding: 0.75rem;
  border: 1px solid #ddd;
}

th {
  background-color: #07538f;
  color: white;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.success {
  color: green;
  font-weight: bold;
}

.failed {
  color: red;
  font-weight: bold;
}
</style>
