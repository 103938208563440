<template>
  <div class="add-cell-form">
    <h3>{{ isEdit ? "Edit Block" : "Add New Block" }}</h3>
    <form @submit.prevent="handleSubmit">
      <!-- Block Name -->
      <div class="form-group">
        <label for="cellName">Title:</label>
        <input
          type="text"
          id="cellName"
          v-model="formData.cellName"
          placeholder="Enter Block name"
        />
      </div>

      <!-- Image Upload -->
      <div class="form-group">
        <label for="blockImage">Image:</label>
        <input
          type="file"
          id="blockImage"
          accept="image/*"
          @change.stop="onImageUpload"
        />
      </div>

      <!-- Aspect Ratio -->
      <div class="form-group">
        <label for="aspectRatio">Aspect Ratio:</label>
        <select id="aspectRatio" v-model="formData.aspectRatio">
          <option value="1:1">1:1</option>
          <option value="16:9">16:9</option>
          <option value="16:5">16:5</option>
        </select>
      </div>

      <!-- Text Color -->
      <div class="form-group">
        <label for="textColor">
          Title Color:
          <span
            class="color-preview"
            :style="{ backgroundColor: formData.textColor }"
            @click="triggerColorPicker('textColor')"
          ></span>
        </label>
        <input
          type="color"
          id="textColor"
          v-model="formData.textColor"
          ref="textColorPicker"
          class="hidden-input"
        />
      </div>

      <!-- Background Color -->
      <div class="form-group">
        <label for="backgroundColor">
          Background Color:
          <span
            class="color-preview"
            :style="{ backgroundColor: formData.backgroundColor }"
            @click="triggerColorPicker('backgroundColor')"
          ></span>
        </label>
        <input
          type="color"
          id="backgroundColor"
          v-model="formData.backgroundColor"
          ref="backgroundColorPicker"
          class="hidden-input"
        />
      </div>

      <!-- Action Picklist -->
      <div class="form-group">
        <label for="action">Action:</label>
        <select id="action" v-model="formData.action" @change="onActionChange">
          <option value="openUrl">Open URL</option>
          <option value="openPage">Open Page</option>
        </select>
      </div>

      <!-- URL -->
      <div v-if="formData.action === 'openUrl'" class="form-group">
        <label for="url">URL:</label>
        <input
          type="url"
          v-model="formData.url"
          @input="enforceWebsitePrefix"
          required
          placeholder="https://example.com"
        />
      </div>

      <!-- Open in Browser -->
      <div v-if="formData.action === 'openUrl'" class="form-group">
        <label>
          <input type="checkbox" v-model="formData.openInBrowser" />
          Open in Browser
          <span style="color: red">MUST BE CHECKED FOR GIVING URLS</span>
        </label>
      </div>

      <!-- Active Status -->
      <div class="form-group">
        <label>
          <input type="checkbox" v-model="formData.isActive" />
          Active
          <small v-if="formData.isActive === false" style="color: red"
            >Only active blocks appear in the App</small
          >
        </label>
      </div>

      <!-- Block Preview -->
      <div class="block-preview">
        <div
          class="preview-container"
          :style="{
            aspectRatio: aspectRatioMap[formData.aspectRatio],
            backgroundColor: formData.backgroundColor,
            backgroundImage: this.formData.backgroundImageUrl
              ? `url(${this.formData.backgroundImageUrl})`
              : this.previewImage
              ? `url(${this.previewImage})`
              : 'none',
          }"
        >
          <span :style="{ color: formData.textColor }" class="preview-text">{{
            formData.cellName
          }}</span>
        </div>
      </div>

      <!-- Submit Button -->
      <div class="form-actions">
        <button type="button" class="cancel-btn" @click="$emit('cancel')">
          Cancel
        </button>
        <button type="submit" class="common-btn">
          {{ isEdit ? "Save Changes" : "Add Cell" }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "AddEditCellForm",
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    initialCellData: {
      type: Object,
      default: () => ({
        id: null,
        cellName: "",
        url: "",
        textColor: "#000000",
        backgroundColor: "#ffffff",
        parentCellId: 0,
        openInBrowser: false,
        isActive: true,
        hasChildCell: false,
        aspectRatio: "16:9",
      }),
    },
    isTopLevel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        ...this.initialCellData,
        textColor: this.initialCellData.textColor || "#000000",
        backgroundColor: this.initialCellData.backgroundColor || "#ffffff",
        aspectRatio:
          this.initialCellData.aspectRatio?.replace(" / ", ":") || "16:5",
        backgroundImageUrl: this.initialCellData.backgroundImageUrl || null,
        action:
          this.initialCellData.hasChildCell == true ? "openPage" : "openUrl",
        isActive: this.initialCellData.isActive || false,
      },
      previewImage: this.initialCellData.backgroundImageUrl || null,
      aspectRatioMap: {
        "1:1": "1 / 1",
        "16:9": "16 / 9",
        "16:5": "16 / 5",
      },
      errors: {},
    };
  },
  methods: {
    onActionChange() {
      if (this.formData.action === "openPage") {
        this.formData.hasChildCell = true;
        this.formData.url = ""; // Clear URL field if switching from Open URL
        this.formData.openInBrowser = false; // Reset Open in Browser checkbox
      } else if (this.formData.action === "openUrl") {
        this.formData.hasChildCell = false; // Unset hasChildCell if switching to Open URL
      }
    },
    enforceWebsitePrefix() {
      if (!this.formData.url.startsWith("https://")) {
        this.formData.url = "https://";
      }
    },
    handleSubmit() {
      const formData = {
        ...this.formData,
        backgroundImageUrl:
          this.previewImage || this.formData.backgroundImageUrl || null,
      };

      if (this.isEdit) {
        this.$emit("edit-cell", formData);
      } else {
        this.$emit("add-cell", formData);
      }
    },
    onImageUpload(event) {
      const file = event.target.files[0]; // Get the selected file

      if (file) {
        // Clear old image references
        this.previewImage = null;
        this.formData.backgroundImageUrl = null;

        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result; // Set new preview image
          this.formData.imageFile = file; // Attach the file to formData
          this.$emit("update-preview", this.previewImage); // Emit preview to parent
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
          alert("Failed to read the file. Please try again.");
        };

        reader.readAsDataURL(file);
      } else {
        // File dialog was canceled; reset input without any other actions
        console.log("File dialog canceled.");
        event.target.value = ""; // Reset the file input field
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    onHasChildCellChange() {
      if (this.formData.hasChildCell) {
        this.formData.url = null;
      }
    },
    triggerColorPicker(pickerRef) {
      if (this.$refs[pickerRef]) {
        this.$refs[pickerRef].click();
      } else {
        console.error(`Color picker ref "${pickerRef}" is not defined.`);
      }
    },
  },
};
</script>

<style scoped>
.add-cell-form {
  max-width: 500px;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

h3 {
  margin-bottom: 1rem;
  text-align: center;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

.color-preview {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border-radius: 50%;
  border: 2px solid #000;
  vertical-align: middle;
}

.hidden-input {
  position: absolute !important;
  opacity: 0 !important;
  pointer-events: none !important;
}

input,
button {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="checkbox"] {
  width: auto;
}

.common-btn {
  background-color: #07538f;
  color: white;
  cursor: pointer;
}

.common-btn:hover {
  background-color: #00426c;
}

.cancel-btn {
  background-color: #ff8500;
  color: white;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #e07600;
}

.block-preview {
  margin: 1rem 0;
  text-align: center;
}

.preview-container {
  position: relative; /* Ensure positioning context for child elements */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.preview-text {
  position: absolute; /* Position relative to the container */
  bottom: 16px; /* Distance from the bottom of the container */
  left: 16px; /* Distance from the left of the container */
  font-size: 24px; /* Set the desired font size */
  font-weight: bold; /* Optional: Make the text bold */
  color: inherit; /* Use the dynamically applied text color */
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3); /* Add shadow for readability */
  z-index: 1; /* Ensure it stays above the background */
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
}
</style>
