<template>
  <nav class="navbar">
    <div class="navbar-brand">
      <a href="#">my<span class="highlighted-brand-color">C</span>helper</a>
    </div>
    <button class="menu-toggle" @click="toggleMenu">
      <span v-if="!menuOpen">&#9776;</span>
      <span v-else>&#10006;</span>
    </button>
    <ul :class="['navbar-links', { 'is-active': menuOpen }]">
      <button
        @click="selectView('ChurchInfo')"
        :class="['navbar-btn', { selected: selectedView === 'ChurchInfo' }]"
      >
        Church Info
      </button>
      <button
        @click="selectView('AppHomeScreen')"
        :class="['navbar-btn', { selected: selectedView === 'AppHomeScreen' }]"
      >
        App Home Screen
      </button>
      <button
        @click="selectView('MassNotifications')"
        :class="[
          'navbar-btn',
          { selected: selectedView === 'MassNotifications' },
        ]"
      >
        Mass Notifications
      </button>
      <!-- <button
        @click="selectView('People')"
        :class="['navbar-btn', { selected: selectedView === 'People' }]"
      >
        People
      </button>
      <button
        @click="selectView('ServingGroups')"
        :class="['navbar-btn', { selected: selectedView === 'ServingGroups' }]"
      >
        Serving Groups
      </button>
      <button
        @click="selectView('PublicGroups')"
        :class="['navbar-btn', { selected: selectedView === 'PublicGroups' }]"
      >
        Public Groups
      </button> -->
    </ul>
  </nav>
</template>

<script>
export default {
  name: "AdminNavBar",
  props: {
    selectedView: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedViewCopy: this.selectedView, // Create a local copy of the prop
      menuOpen: false,
    };
  },
  watch: {
    // Watch for changes in the prop and update the local copy
    selectedView(newVal) {
      this.selectedViewCopy = newVal;
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    selectView(view) {
      this.selectedViewCopy = view; // Update the local copy
      this.$emit("select-view", view); // Notify the parent of the change
    },
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #07538f;
  color: white;
}

.navbar-brand a {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.menu-toggle {
  font-size: 1.5rem;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  display: none;
}

.navbar-links {
  display: flex;
  list-style: none;
  gap: 1.5rem;
}

.navbar-btn {
  color: white;
  background-color: #07538f;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.navbar-btn.selected {
  background-color: #ff8500; /* Highlight color for selected button */
}

.navbar-links.is-active {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .navbar-links {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #07538f;
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
    z-index: 10;
  }

  .navbar-links.is-active {
    display: flex;
  }
}
</style>
